import React, { useState } from 'react';
import './TableView.css';

const TableView = ({ cards, calculatePrice, calculateSteppedPrice, userSettings, onRowClick, currencySymbol, addToCart, exchangeRates, userCurrency }) => {
  const [cardStates, setCardStates] = useState(
    cards.map(card => {
      const condition = 'NM';
      const printing = card.skus[0]?.printingName || 'Regular';
      const basePrice = card.skus[0]?.lowPrice || 0;
      const exchangeRate = exchangeRates[userCurrency] || 1;
      return {
        condition,
        printing,
        trendPrice: basePrice * exchangeRate
      };
    })
  );
  const [hoverCard, setHoverCard] = useState(null);

  const formatPrice = (price) => {
    if (isNaN(price) || price === null) return 'N/A';
    return `${currencySymbol}${price.toFixed(2)}`;
  };

  const checkPriceGap = (card, printing) => {
    // Get all SKUs for the current printing
    const skus = card.skus.filter(sku => 
      sku.langAbbr === 'EN' && 
      sku.printingName === printing
    );

    // Sort SKUs by condition in order NM -> LP -> MP -> HP -> DM
    const conditionOrder = { NM: 0, LP: 1, MP: 2, HP: 3, DM: 4 };
    const sortedSkus = skus.sort((a, b) => conditionOrder[a.condAbbr] - conditionOrder[b.condAbbr]);

    let maxGap = 0;
    // Check for price gaps between adjacent conditions
    for (let i = 0; i < sortedSkus.length - 1; i++) {
      const currentPrice = sortedSkus[i].lowPrice;
      const nextPrice = sortedSkus[i + 1].lowPrice;

      // Skip if either price is 0 or null
      if (!currentPrice || !nextPrice) continue;

      // Calculate percentage difference
      const priceDiff = ((currentPrice - nextPrice) / currentPrice) * 100;
      maxGap = Math.max(maxGap, priceDiff);
    }

    // Return warning level based on gap percentage
    if (maxGap >= 30) {
      return 'severe';
    } else if (maxGap >= 15) {
      return 'moderate';
    }
    return null;
  };

  const getTrendPrice = (card, condition, printing) => {
    // Get the actual SKU for the selected condition
    const currentSku = card.skus.find(sku =>
      sku.langAbbr === 'EN' && 
      sku.printingName === printing &&
      sku.condAbbr === condition
    );

    // Get actual price for current condition
    const actualPrice = currentSku?.lowPrice || 0;

    // Get NM SKU for stepped calculation
    const nmSku = card.skus.find(sku =>
      sku.langAbbr === 'EN' && 
      sku.printingName === printing &&
      sku.condAbbr === 'NM'
    );

    const nmPrice = nmSku?.lowPrice || 0;
    const steppedPrice = calculateSteppedPrice(condition, nmPrice);

    // Use the lower of actual price or stepped price
    const basePrice = actualPrice > 0 && actualPrice < steppedPrice ? actualPrice : steppedPrice;
    
    // Get the exchange rate for currency conversion
    const exchangeRate = exchangeRates[userCurrency] || 1;
    return basePrice * exchangeRate;
  };

  const handleConditionChange = (index, newCondition) => {
    setCardStates(prevStates => {
      const newStates = [...prevStates];
      const card = cards[index];
      const currentPrinting = newStates[index].printing;
      const newTrendPrice = getTrendPrice(card, newCondition, currentPrinting);
      newStates[index] = { 
        ...newStates[index], 
        condition: newCondition,
        trendPrice: newTrendPrice
      };
      return newStates;
    });
  };

  const handlePrintingChange = (index, newPrinting) => {
    setCardStates(prevStates => {
      const newStates = [...prevStates];
      const card = cards[index];
      const currentCondition = newStates[index].condition;
      const newTrendPrice = getTrendPrice(card, currentCondition, newPrinting);
      newStates[index] = { 
        ...newStates[index], 
        printing: newPrinting,
        trendPrice: newTrendPrice
      };
      return newStates;
    });
  };

  const handleAddToCart = (card, isCash, price) => {
    const cardState = cardStates[cards.indexOf(card)];
    // Find the correct SKU based on condition and printing
    const relevantSku = card.skus.find(sku => 
      sku.condAbbr === cardState.condition && 
      sku.printingName === cardState.printing
    );
    addToCart(
      card, 
      cardState.condition, 
      cardState.printing, 
      1, 
      cardState.trendPrice, 
      relevantSku?.skuId,
      isCash, 
      price
    );
  };

  const getWarningClass = (warningLevel) => {
    switch (warningLevel) {
      case 'severe':
        return 'warning-row-severe';
      case 'moderate':
        return 'warning-row-moderate';
      default:
        return '';
    }
  };

  return (
    <div>
      <div className="warning-legend">
        <div className="warning-legend-item">
          <div className="warning-legend-color severe"></div>
          <span>Price gap ≥ 30% between conditions - Manual check required</span>
        </div>
        <div className="warning-legend-item">
          <div className="warning-legend-color moderate"></div>
          <span>Price gap 15-30% between conditions - Review recommended</span>
        </div>
      </div>
      <div className="table-responsive">
        <table className="card-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Expansion</th>
              <th>Number</th>
              <th>Condition</th>
              <th>Printing</th>
              <th>Trend Price</th>
              <th>Cash Offer</th>
              <th>Credit Offer</th>
            </tr>
          </thead>
          <tbody>
            {cards.map((card, index) => {
              const { condition, printing, trendPrice } = cardStates[index];
              const { cashPrice, creditPrice } = calculatePrice(card, condition, printing);
              const warningLevel = checkPriceGap(card, printing);
              
              return (
                <tr 
                  key={card._id} 
                  onClick={() => onRowClick(card)}
                  onMouseEnter={() => setHoverCard(card)}
                  onMouseLeave={() => setHoverCard(null)}
                  className={getWarningClass(warningLevel)}
                >
                  <td>{card.name}</td>
                  <td>{card.expansionName}</td>
                  <td>{card.number}</td>
                  <td>
                    <select 
                      value={condition} 
                      onChange={(e) => handleConditionChange(index, e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {['NM', 'LP', 'MP', 'HP', 'DM'].map(cond => (
                        <option key={cond} value={cond}>{cond}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select 
                      value={printing} 
                      onChange={(e) => handlePrintingChange(index, e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {card.skus.map(sku => sku.printingName).filter((v, i, a) => a.indexOf(v) === i).map(print => (
                        <option key={print} value={print}>{print}</option>
                      ))}
                    </select>
                  </td>
                  <td>{formatPrice(trendPrice)}</td>
                  <td>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToCart(card, true, cashPrice);
                      }}
                      className="add-to-cart-btn cash"
                    >
                      {formatPrice(cashPrice)}
                    </button>
                  </td>
                  <td>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToCart(card, false, creditPrice);
                      }}
                      className="add-to-cart-btn credit"
                    >
                      {formatPrice(creditPrice)}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {hoverCard && (
          <div className="card-hover-image">
            <img src={hoverCard.image} alt={hoverCard.name} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TableView;
